
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































.landing {
  // prettier-ignore
  @include fluid(gap, (l: 40px, xxl: 80px));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.landing__visual-container {
  // prettier-ignore
  @include fluid(max-width, (l: 400px, xxl: 500px));

  position: relative;
  width: 100%;
  height: 100%;
}

.landing__visual {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.landing__content-container {
  max-width: 72rem;
  text-align: center;

  @include mq($from: l) {
    text-align: left;
  }
}

.landing__title {
  // prettier-ignore
  @include fluid(font-size, (s: 36px, l: 72px));

  margin-block: 0 3.2rem;
}

.landing__subtitle {
  // prettier-ignore
  @include fluid(font-size, (s: 18px, l: 24px));

  margin-block: 0 3.2rem;
}

.landing__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3.2rem;
  margin-bottom: 6rem;

  @include mq($from: l) {
    justify-content: flex-start;
    margin-bottom: unset;
  }
}
