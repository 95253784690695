
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





















































































































































































.help-me-choose {
  --header-height: 5rem;
  --extra-padding-block-start: 2rem;

  max-width: 1380px;
  min-height: 100vh;
  margin: auto;

  // Move content below header
  /* stylelint-disable-next-line declaration-colon-newline-after */
  padding-block-start: calc(
    var(--extra-padding-block-start) + var(--header-height)
  );

  @include mq($from: l) {
    --header-height: 12rem;
    --extra-padding-block-start: 4rem;
  }

  @include mq($until: 1420px) {
    padding-inline: 2rem;
  }
}

.step {
  border: 1px $c-gray solid;
}

label {
  margin-inline-end: 2rem;
}

.hmc {
  display: flex;
}

.result {
  td {
    padding: 1rem;
    border: 1px $c-gray solid;
  }
}

.breadcrumbs {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  color: $c-midnight-darkest;
}

.breadcrumbs__item {
  // prettier-ignore
  @include fluid(font-size, (s: 9px, l: 11px));

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 27.3rem;
  padding: 0.8rem;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.733px;
  border-top: 1px $c-midnight-darkest solid;
  border-right: 1px $c-midnight-darkest solid;
  border-bottom: 1px $c-midnight-darkest solid;
  opacity: 0.3;

  &:first-of-type {
    border-left: 1px $c-midnight-darkest solid;
    border-radius: 10rem 0 0 10rem;
  }

  &:last-of-type {
    border-radius: 0 10rem 10rem 0;
  }

  &.active {
    color: $white;
    background-color: $c-midnight-darkest;
    opacity: 1;
  }

  &.complete {
    opacity: 1;
  }
}
