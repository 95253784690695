
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































































































































































































































































$thumbnail-size-s: 80px;
$thumbnail-size-l: 100px;

.result {
  // prettier-ignore
  @include fluid(padding-top, (s: 32px, l: 72px));

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  padding-bottom: 7rem;

  @include mq($from: l) {
    gap: 6rem;
    flex-direction: row;
  }
}

.result__content {
  // prettier-ignore
  @include fluid(gap,(xxs: 30px, xxl: 40px));

  display: flex;
  flex-direction: column;
}

.result__header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;

  @include mq(l) {
    gap: 4rem;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }
}

.result__header__image {
  margin: 0 auto;

  ::v-deep img {
    display: block;
    width: 16rem;
    height: auto;
  }

  @include mq(l) {
    margin: 0;

    ::v-deep img {
      width: 14rem;
    }
  }
}

.result__household {
  // prettier-ignore
  @include fluid(gap, (s: 16px, l: 20px));

  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include mq($until: l) {
    justify-content: center;
  }
}

.result__household__item {
  // prettier-ignore
  @include fluid(width, (s: $thumbnail-size-s, l: $thumbnail-size-l));

  // prettier-ignore
  @include fluid(height, (s: $thumbnail-size-s, l: $thumbnail-size-l));

  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.result__household__thumbnail {
  @include image-fit;
}

.result__title {
  --arrow-gutter: 2rem;

  // prettier-ignore
  @include fluid(--arrow-width, (l: 100px, xl: 160px));

  position: relative;
  margin: 0;

  span {
    display: block;
    line-height: 1.2;

    &:nth-child(1) {
      // prettier-ignore
      @include fluid(font-size, (s: 28px, l: 32px));
    }

    &:nth-child(2) {
      color: $c-pink-medium;
      font-size: 4rem;
    }
  }
}

.result__desktop-arrow {
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  width: 10rem;
  height: auto;

  @include mq($from: l) {
    display: block;
  }
}

.result__text {
  // prettier-ignore
  @include fluid(font-size, (s: 15px, l: 18px));

  line-height: 1.6;
}

.result__mobile-svg-arrow {
  height: 10rem;
  margin: auto;

  @include mq($from: l) {
    display: none;
  }
}

.result__pack {
  --border-radius: 1rem;

  flex-shrink: 0;
  width: min(38rem, 100%);
  margin: auto;
  text-align: center;
  border-radius: var(--border-radius);
  box-shadow: 0 15px 40px 0 rgb(0 0 0 / 15%);
}

.result__pack__top,
.result__pack__bottom {
  padding: 3.2rem;
}

.result__pack__bottom {
  position: relative;
  padding: 3rem;
  color: $white;
  background-color: $c-bunting;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.result__pack__title {
  margin: 0 0 1.2rem;
  font-family: $ff-alt;
  font-size: 1.8rem;
  font-weight: 600;
}

.result__pack__logo {
  display: block;
  width: 13rem;
  margin: 0 auto;
}

.result__pack__rgus {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.result__pack__rgu {
  // prettier-ignore
  @include fluid(font-size, (s: 18px, l: 24px));

  line-height: 1.5;
}

.result__pack__label {
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% -50%;
  width: max-content;
}

.result__pack__price {
  color: $c-mandarine;
  font-family: $ff-alt;
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 1.2;
}

.result__pack__price-old {
  position: relative;
  display: inline-block;
  font-family: $ff-alt;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1;

  &.has-promo {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $c-mandarine;
      border-radius: 2px;
      opacity: 0.7;
    }
  }
}

.result__pack__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.result__pack__secondary-action {
  color: $white;
  font-size: 1.3rem;
  font-weight: 500;
}
