
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































































.usage {
  position: relative;
  z-index: 1;
  margin-top: auto;
  font-family: $ff-alt;
  font-weight: 600;
}

.usage__counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-block-end: 1rem;
}

.usage__title {
  // prettier-ignore
  @include fluid(font-size, (s: 15px, l: 16px));

  margin: 0 0 1.2rem;
  color: $white;
  line-height: 1.2;
  text-align: center;
}

.usage__counter__value {
  flex-grow: 1;
  width: 0;
  margin: 0;
  padding: 0.8rem;
  font-size: 1.4rem;
  text-align: center;
  background-color: $white;
  border-radius: 5rem;
}

.increment-button {
  flex-shrink: 0;
}
