
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































.device {
  // prettier-ignore
  @include fluid(border-radius, (s: 15px, l: 36px));

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  max-width: 26rem;
  aspect-ratio: 260 / 353;
  padding: 2.4rem;
}

.device__picture {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.device__counter {
  // prettier-ignore
  @include fluid(gap, (s: 12px, l: 16px));

  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-end: 1rem;
}

.device__counter__button {
  // prettier-ignore
  @include fluid(width, (s: 44px, l: 40px));

  // prettier-ignore
  @include fluid(height, (s: 44px, l: 40px));

  // prettier-ignore
  @include fluid(font-size, (s: 36px, l: 24px));

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.12;
  background-color: rgb(255 255 255 / 40%);
  border-width: 0;
  border-radius: 50%;
  transition: all 0.2s $ease-out-quad;

  &:disabled {
    cursor: disabled;
    opacity: 0.25;
  }

  &:active:not(:disabled) {
    scale: 0.95;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $white;
  }
}

.device__counter__value {
  // prettier-ignore
  @include fluid(width, (s: 53px, l: 72px));

  // prettier-ignore
  @include fluid(height, (s: 53px, l: 72px));

  // prettier-ignore
  @include fluid(font-size, (s: 36px, l: 48px));

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.12;
  background-color: $white;
  border-width: 0;
  border-radius: 50%;
}

.device__title {
  // prettier-ignore
  @include fluid(font-size, (s: 15px, l: 18px));

  margin: 0;
  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.12;
  text-align: center;
}
