
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




















.title {
  // prettier-ignore
  @include fluid(font-size, (s: 28px, l: 48px));

  // prettier-ignore
  @include fluid(margin-block-start, (s: 32px, l: 40px));

  // prettier-ignore
  @include fluid(margin-block-end, (s: 32px, l: 52px));

  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}
