
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































.help-me-choose__footer {
  // prettier-ignore
  @include fluid(padding-block-start, (s: 24px, l: 52px));

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 6rem;
}

.help-me-choose__footer__content {
  // prettier-ignore
  @include fluid(font-size, (s: 28px, l: 32px));

  order: 1;
  width: 100%;
  margin-block-end: 2.4rem;
  font-weight: 400;
  text-align: center;

  ::v-deep {
    p {
      margin: 0;
    }

    span {
      color: $c-pink-medium;
    }
  }

  @include mq(l) {
    @include center-y;

    left: 0;
    width: 100%;
    margin-block-end: 0;
  }
}

.help-me-choose__footer__previous {
  order: 2;

  @include mq($until: m) {
    display: grid;
    place-content: center;
    width: 4.4rem;
    height: 4.4rem;

    ::v-deep {
      .btn__link {
        display: none;
      }

      .btn__icon {
        width: 100%;
        height: 100%;
        margin-left: 0;
      }

      .icon {
        width: 3rem;
        height: 3rem;
      }
    }

    &:hover,
    &:focus {
      ::v-deep {
        /* stylelint-disable-next-line max-nesting-depth */
        .icon {
          transform: translateX(0);
        }
      }
    }
  }

  @include mq($from: m) {
    ::v-deep {
      .btn__icon {
        display: none;
      }
    }
  }

  @include mq(l) {
    order: 1;
  }
}

.help-me-choose__footer__next {
  order: 3;
  height: 4.4rem;

  .no-previous & {
    margin-left: auto;
  }

  ::v-deep {
    .btn__icon {
      display: none;
    }
  }

  &.loading {
    cursor: wait;
    pointer-events: none;

    ::v-deep {
      .btn__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: 0;
        translate: -50% -50%;

        /* stylelint-disable-next-line max-nesting-depth */
        svg {
          width: 2rem;
          height: 2rem;
          animation: spin 1s linear infinite;
        }
      }

      .btn__link__title {
        opacity: 0;
      }
    }
  }

  @include mq(l) {
    height: auto;
  }
}

.help-me-choose__footer__next,
.help-me-choose__footer__previous {
  &.disabled {
    background-color: $gray-medium;
    opacity: 0.5;
    pointer-events: none;
  }
}

@keyframes spin {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}
