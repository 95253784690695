
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























.increment-button {
  // prettier-ignore
  @include fluid(width, (s: 44px, l: 40px));

  // prettier-ignore
  @include fluid(height, (s: 44px, l: 40px));

  // prettier-ignore
  @include fluid(font-size, (s: 36px, l: 24px));

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.12;
  background-color: rgb(255 255 255 / 40%);
  border-width: 0;
  border-radius: 50%;
  transition: all 0.2s $ease-out-quad;

  &:disabled {
    cursor: disabled;
    opacity: 0.25;
  }

  &:active:not(:disabled) {
    scale: 0.95;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $white;
  }
}
