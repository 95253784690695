
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





















































































































































































.assistant {
  --card-max-width: 26rem;

  // Forcing width ensure breadcrumbs and footer have a consistent width throughout the steps
  width: 100%;
}

::v-deep {
  .assistant-carousel {
    .carousel__item {
      width: 100%;
      max-width: var(--card-max-width);
    }
  }
}
