
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































.card {
  // prettier-ignore
  @include fluid(border-radius, (s: 15px, l: 36px));

  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  max-width: var(--card-max-width, 26rem);
  height: 100%;
  aspect-ratio: 260 / 353;
  padding: 2.4rem;
}

.card__picture {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
