
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































































.usage-card {
  // prettier-ignore
  @include fluid(padding, (s: 14px, l: 16px));

  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $c-midnight-darkest;
  border-radius: 0;

  // Note: animating height is bad but trying to make it work using transforms while maintaining aspect ratio and nested elements scale is even worse.
  // Not be optimal performance-wise but the complexity trade off is not worth it.
  transition: height 0.4s $ease-in-out-quart;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 17.3rem;
    max-height: 100%;
    background: linear-gradient(180deg, transparent 0%, #000 100%);
  }
}

::v-deep {
  .card__picture {
    transition: opacity 0.4s $ease-in-out-quart;

    .no-usage & {
      opacity: 0;
    }
  }

  .assistant-carousel {
    .carousel__item {
      display: flex;
      align-items: flex-end;
      min-height: 35.3rem;
    }
  }
}
