
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































.breadcrumbs {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  color: $c-midnight-darkest;
}

.breadcrumbs__item__button {
  width: 100%;
  padding: 0.8rem;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.733px;
  background-color: transparent;
  border-width: 0;
  pointer-events: none;
}

.breadcrumbs__item {
  // prettier-ignore
  @include fluid(font-size, (s: 9px, l: 11px));

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 27.3rem;
  border-top: 1px $c-midnight-darkest solid;
  border-right: 1px $c-midnight-darkest solid;
  border-bottom: 1px $c-midnight-darkest solid;
  opacity: 0.3;

  &:first-of-type {
    border-left: 1px $c-midnight-darkest solid;
    border-radius: 10rem 0 0 10rem;
  }

  &:last-of-type {
    border-radius: 0 10rem 10rem 0;
  }

  &.active {
    color: $white;
    background-color: $c-midnight-darkest;
  }

  &.complete {
    .breadcrumbs__item__button {
      pointer-events: auto;
    }
  }

  &.active,
  &.complete {
    opacity: 1;
  }
}
