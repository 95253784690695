
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































































































































































































































































































































































































































































































.hmc-step__title {
  // prettier-ignore
  @include fluid(font-size, (s: 28px, l: 48px));

  // prettier-ignore
  @include fluid(margin-block-start, (s: 32px, l: 40px));

  // prettier-ignore
  @include fluid(margin-block-end, (s: 32px, l: 52px));

  font-family: $ff-alt;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.household {
  ::v-deep {
    .carousel__scroller {
      // Prevent unwanted scrollbar during carousel enter / leave transitions
      overflow-y: hidden;

      &:not(.can-scroll) {
        justify-content: center;
      }
    }

    .carousel__item {
      width: 100%;
      max-width: 26rem;
    }
  }
}

.household__item {
  // prettier-ignore
  @include fluid(border-radius, (s: 15px, l: 36px));

  position: relative;
  width: 100%;
  max-width: 26rem;

  // Fix iOS bottom border clipping issue https://git.epic.net/voo/website-v3/track/-/issues/1278#note_318321
  margin-bottom: 1px;
  aspect-ratio: 260 / 353;
}

.household__item__edit {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  border-radius: inherit;
}

.household__item__picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.household__item__remove {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  right: 1.5rem;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

.household__item__remove__svg {
  fill: $white;
  stroke: currentcolor;
  transform: rotate(45deg);
}

.household__add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: $c-cultured;
  border: 1px $c-weldon-blue solid;
  border-radius: inherit;

  &.hover-focus {
    --c-focus-ring: rgb(124 150 164 / 10%);
  }
}

.household__add__svg {
  fill: $c-weldon-blue;
}

.household-member-picker-container {
  position: fixed;
  z-index: $z-index-header + 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.household-member-picker-container__close-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  cursor: initial;
  border-width: 0;

  @include mq($from: m) {
    display: block;
  }
}

.household-member-picker {
  $padding: 4rem;

  /* stylelint-disable-next-line length-zero-no-unit */
  --header-block-offset: 0px;
  --padding-top: calc(#{$padding} + var(--header-block-offset));

  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding: $padding;
  padding-top: var(--padding-top);
  background: $white;

  @include mq($until: m) {
    width: 100%;
    height: 100%;

    .voo-header.static + .voo-root &,
    .voo-header.up + .voo-root & {
      --header-block-offset: #{$header-height};
    }
  }

  @include mq($from: m) {
    top: 50%;
    left: 50%;
    max-width: 66rem;
    max-height: 80vh;

    // Using transform instead of translate because translate does not work well w GSAP animation
    transform: translate(-50% -50%);
    border-radius: 2rem;
  }
}

.household-member-picker__close {
  position: absolute;
  top: calc(1.5rem + var(--header-block-offset));
  right: 1.5rem;
  width: 2.2rem;
  height: 2.2rem;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
}

.household-member-picker__close___svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: $c-midnight-darkest;
  transform: rotate(45deg);
}

.household-member-picker__label {
  margin-block: 0 4rem;
  font-family: $ff-alt;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.household-member-picker__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: fit-content;
  margin: auto;
  padding: 0;
  list-style-type: none;
}

.household-member-picker__list-item {
  width: 100%;
  aspect-ratio: 1;

  @include mq($from: m) {
    max-width: 10rem;
  }
}

.household-member-picker__button {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  border-radius: 50%;
}

.household-member-picker__picture {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.hover-focus {
  --c-focus-ring: rgb(255 255 255 / 30%);

  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    pointer-events: none;
    box-shadow: 0 0 0 0.5rem var(--c-focus-ring) inset;
    opacity: 0;
    transition: opacity 0.2s $ease-out-quad;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}
